import React from "react"
import Header from "../components/documentation/Header"
import Footer from "../components/Footer"
import SEO from "../components/seo"
import { PrimaryButton } from "../components/Buttons"
import { primaryBlue } from "../components/palette"
import styled from "styled-components"
import { Breakpoint } from "../components/breakpoints"
import { graphql } from "gatsby"
import "../components/layout.css"
import {FixedPageList} from "./PageList"
import TableOfContent from "./TableOfContent"

const Section = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (min-width: ${Breakpoint.small}) {
    padding-bottom: 6rem;
    align-items: flex-start;
    padding-top: 10vh;
  }
  background-color: ${primaryBlue};
  color: ${primaryBlue};
`

const RegistrationSection = styled.section`
  background-color: white;
  max-width: 500px;
  padding: 3rem 1rem;
  @media only screen and (min-width: ${Breakpoint.small}) {
    padding: 3rem;
    border-radius: 1em;
  }
`

const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`

const MarginLeft = styled.div`
  margin-left: 0.5rem;
`
const Container = styled.div`
  max-width: 41rem;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5rem;
  margin-bottom: 10rem;
`
const RegisterButton = () => (
  <MarginLeft>
    <PrimaryButton to={"/register"}>Register</PrimaryButton>
  </MarginLeft>
)

const Main = styled.main`
  position: relative;
@media (max-width: 700px) {
    padding-left: 1rem;
    padding-right: 1rem;
}
`

const DocumentationTemplate = ({ data }) => {
  const { frontmatter, html, headings } = data.markdownRemark
  return (
    <>
      <div id="page-container">
        <Header siteTitle={"Triangula"} selectedPath={frontmatter.path} tableOfContent={headings} />
        <Main id={"content-wrap"}>
          <SEO title={frontmatter.title} lang={"nb"}/>
          <FixedPageList selectedPath={frontmatter.path} tableOfContent={headings} />
          <Container>
            <h1>{frontmatter.title}</h1>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </Container>
        </Main>
  {/* <ColorTest /> */}
        {/* <Footer/> */}
      </div>
    </>
  )
}

const ColorTest = () => {
return (
  <div style={{display: "flex", height: "20rem", paddingLeft: "10rem", paddingRight: "10rem", paddingBottom: "20rem"}}>
    <p>
      
    </p>
    {[0,1,2,3,4,5,6,7,8,9,10,20,30,40,50,60].map((i) => {
      return <RoundBox diff={60000*i}/>
    })}
</div>
)
}

const RoundBox = ({diff}) => {
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center", marginLeft: "0.2rem"}}>

    <div style={{
      height: "2rem",
      width: "2rem",
      borderRadius: "20px",
      background: heatMapColorForValue(1, 1 + diff)
    }}>
    </div>
    <div>
      <p style={{fontSize: "0.8rem", fontWeight: "bolder"}}>{diff/60000}</p>
    </div>
      </div>
  )
}

const getHeatMapColor = (diffInMinutes: number) => {
  if (diffInMinutes < 2.5) {
    return (diffInMinutes * 2 * 60) / 5;
  } else {
    return 40 + diffInMinutes * 4;
  }
};

export const heatMapColorForValue = (millis: number, latestMillis: number) => {
  const diffInSeconds = (latestMillis - millis) / 1000;
  const diffInMinutes = diffInSeconds / 60;

  const h =
    diffInMinutes > 45
      ? 240
      : diffInMinutes < 0
      ? 0
      : getHeatMapColor(diffInMinutes);
  return "hsl(" + h + ", 100%, 50%)";
};

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      headings {
        value
        depth
        id
      }
      frontmatter {
        path
        title
      }
    }
  }
`

export default DocumentationTemplate
